// import { legacy_createStore as createStore } from 'redux'

// const initialState = {
//   sidebarShow: true,
//   theme: 'light',
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

// store.js
import { legacy_createStore as createStore } from 'redux';
import rootReducer from './reducer/indexReducer';

const store = createStore(rootReducer);
export default store;

